import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const BinIcon = createSvgIcon(
  <>
    <title>bin</title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="square"
      strokeLinejoin="miter"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <path
        d="M27,10V28a3,3,0,0,1-3,3H8a3,3,0,0,1-3-3V10"
        fill="none"
        stroke="#222222"
        strokeMiterlimit="10"
      />{' '}
      <line
        fill="none"
        stroke="#222222"
        strokeMiterlimit="10"
        x1="16"
        x2="16"
        y1="15"
        y2="25"
      />{' '}
      <line
        fill="none"
        stroke="#222222"
        strokeMiterlimit="10"
        x1="11"
        x2="11"
        y1="15"
        y2="25"
      />{' '}
      <line
        fill="none"
        stroke="#222222"
        strokeMiterlimit="10"
        x1="21"
        x2="21"
        y1="15"
        y2="25"
      />{' '}
      <polyline
        data-cap="butt"
        data-color="color-2"
        fill="none"
        points="11 6 11 1 21 1 21 6"
        strokeLinecap="butt"
        strokeMiterlimit="10"
      />{' '}
      <line
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
        x1="30"
        x2="2"
        y1="6"
        y2="6"
      />
    </g>
  </>
)
