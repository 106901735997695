import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const AutomationIcon = createSvgIcon(
  <>
    <title>automation</title>
    <g className="nc-icon-wrapper" fill="#222222">
      <g
        fill="none"
        fillRule="evenodd"
        id="_Icon-/-Others-/-Automation-/-Black"
        stroke="none"
        strokeWidth="1"
      >
        <g id="automation" stroke="#222222" transform="translate(1 1)">
          <path
            d="M10.625 28.125c0 .833 1.458 1.25 4.375 1.25s4.375-.417 4.375-1.25"
            id="Line-5"
            strokeLinecap="square"
          />
          <circle cx="1.25" cy="23.75" id="Oval" r="1.25" />
          <circle cx="28.75" cy="23.75" id="Oval-Copy" r="1.25" />
          <circle cx="11.25" cy="1.25" id="Oval-Copy-2" r="1.25" />
          <path
            d="M12.5 1.25h.5a2 2 0 0 1 2 2v1.109"
            id="Line-4"
            strokeLinecap="square"
          />
          <path
            d="M1.25 22.5v-3.625a2 2 0 0 1 2-2H26.75a2 2 0 0 1 2 2V22.5"
            id="Line"
            strokeLinecap="square"
          />
          <path
            d="M6.875 16.875v6.75a2 2 0 0 0 2 2h12.25a2 2 0 0 0 2-2v-6.75"
            id="Line-2"
            strokeLinecap="square"
          />
          <path
            d="M6.884 14.375C6.392 7.697 9.097 4.359 15 4.359s8.608 3.338 8.116 10.016H6.884z"
            id="Line-3"
            strokeLinecap="square"
          />
          <rect
            height="2.75"
            id="Rectangle"
            rx="1.375"
            width="11.5"
            x="9.25"
            y="9.25"
          />
        </g>
      </g>
    </g>
  </>
)
