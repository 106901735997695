import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const CsvIcon = createSvgIcon(
  <>
    <title>csv</title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="square"
      strokeLinejoin="miter"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <polyline
        data-cap="butt"
        fill="none"
        points="20,1 20,9 28,9 "
        stroke="#222222"
        strokeLinecap="butt"
        strokeMiterlimit="10"
      />{' '}
      <polyline
        fill="none"
        points="28,13 28,9 20,1 4,1 4,13 "
        stroke="#222222"
        strokeMiterlimit="10"
      />{' '}
      <polyline
        fill="none"
        points="4,27 4,31 28,31 28,27 "
        stroke="#222222"
        strokeMiterlimit="10"
      />{' '}
      <path
        d="M11.5,18.5c-0.4,0-0.7,0.2-0.9,0.5s-0.3,0.7-0.3,1.2c0,1.1,0.4,1.6,1.3,1.6 c0.3,0,0.5,0,0.7-0.1s0.5-0.2,0.7-0.3v1.3c-0.5,0.2-1,0.3-1.6,0.3c-0.9,0-1.5-0.2-2-0.8s-0.7-1.2-0.7-2.2c0-0.6,0.1-1.1,0.3-1.6 s0.5-0.8,1-1s0.9-0.4,1.5-0.4c0.6,0,1.2,0.1,1.8,0.4l-0.5,1.2c-0.2-0.1-0.4-0.2-0.6-0.2S11.7,18.5,11.5,18.5z"
        data-color="color-2"
        data-stroke="none"
        stroke="none"
        strokeLinecap="square"
        strokeLinejoin="miter"
      />{' '}
      <path
        d="M17.9,21.3c0,0.4-0.1,0.7-0.3,0.9s-0.4,0.5-0.8,0.6s-0.7,0.2-1.2,0.2 c-0.4,0-0.7,0-1-0.1s-0.5-0.1-0.8-0.3v-1.4c0.3,0.2,0.6,0.3,0.9,0.4s0.6,0.1,0.9,0.1c0.2,0,0.4,0,0.5-0.1s0.2-0.2,0.2-0.3 c0-0.1,0-0.1-0.1-0.2S16.1,21.1,16,21s-0.3-0.2-0.8-0.4c-0.4-0.2-0.7-0.3-0.8-0.5s-0.3-0.3-0.4-0.5s-0.1-0.4-0.1-0.7 c0-0.5,0.2-0.9,0.6-1.2s0.9-0.4,1.6-0.4c0.6,0,1.2,0.1,1.8,0.4l-0.5,1.2c-0.5-0.2-1-0.4-1.4-0.4c-0.2,0-0.3,0-0.4,0.1 s-0.1,0.2-0.1,0.3c0,0.1,0.1,0.2,0.2,0.3s0.4,0.2,0.9,0.5c0.5,0.2,0.8,0.5,1,0.7S17.9,20.9,17.9,21.3z"
        data-color="color-2"
        data-stroke="none"
        stroke="none"
        strokeLinecap="square"
        strokeLinejoin="miter"
      />{' '}
      <path
        d="M22,17.3h1.7L21.8,23H20l-1.9-5.7h1.7l0.8,2.9c0.2,0.6,0.2,1.1,0.3,1.3 c0-0.2,0.1-0.4,0.1-0.7s0.1-0.5,0.1-0.6L22,17.3z"
        data-color="color-2"
        data-stroke="none"
        stroke="none"
        strokeLinecap="square"
        strokeLinejoin="miter"
      />{' '}
      <rect
        fill="none"
        height="14"
        stroke="#222222"
        strokeMiterlimit="10"
        width="30"
        x="1"
        y="13"
      />
    </g>
  </>
)
