import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const FolderSettingsIcon = createSvgIcon(
  <>
    <title>folder settings</title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="square"
      strokeLinejoin="miter"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <circle
        cx="16"
        cy="18"
        data-color="color-2"
        fill="none"
        r="4"
        strokeMiterlimit="10"
      />
      <line
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
        x1="16"
        x2="16"
        y1="14"
        y2="12"
      />
      <line
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
        x1="18.828"
        x2="20.243"
        y1="15.172"
        y2="13.757"
      />
      <line
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
        x1="20"
        x2="22"
        y1="18"
        y2="18"
      />
      <line
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
        x1="18.828"
        x2="20.243"
        y1="20.828"
        y2="22.243"
      />
      <line
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
        x1="16"
        x2="16"
        y1="22"
        y2="24"
      />
      <line
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
        x1="13.172"
        x2="11.757"
        y1="20.828"
        y2="22.243"
      />
      <line
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
        x1="12"
        x2="10"
        y1="18"
        y2="18"
      />
      <line
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
        x1="13.172"
        x2="11.757"
        y1="15.172"
        y2="13.757"
      />
      <path
        d="M28,29H4a3,3,0,0,1-3-3V2H12l3,5H31V26A3,3,0,0,1,28,29Z"
        fill="none"
        stroke="#222222"
        strokeMiterlimit="10"
      />
    </g>
  </>
)
