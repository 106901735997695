import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const FrameToprightIcon = createSvgIcon(
  <>
    <title>frame topright</title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="square"
      strokeLinejoin="miter"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <line
        data-cap="butt"
        data-color="color-2"
        fill="none"
        strokeLinecap="butt"
        strokeMiterlimit="10"
        x1="26"
        x2="15"
        y1="6"
        y2="17"
      />{' '}
      <rect
        fill="none"
        height="30"
        stroke="#222222"
        strokeMiterlimit="10"
        width="30"
        x="1"
        y="1"
      />{' '}
      <polyline
        data-color="color-2"
        fill="none"
        points=" 18,6 26,6 26,14 "
        strokeMiterlimit="10"
      />
    </g>
  </>
)
