import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const BillIcon = createSvgIcon(
  <>
    <title>bill</title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <polygon
        fill="none"
        points="4 1 28 1 28 30 23 28 20 30 16 28 12 30 9 28 4 30 4 1"
        stroke="#222222"
        strokeMiterlimit="10"
      />{' '}
      <line
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
        x1="9"
        x2="16"
        y1="23"
        y2="23"
      />{' '}
      <line
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
        x1="21"
        x2="23"
        y1="23"
        y2="23"
      />{' '}
      <line
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
        x1="9"
        x2="16"
        y1="18"
        y2="18"
      />{' '}
      <line
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
        x1="21"
        x2="23"
        y1="18"
        y2="18"
      />{' '}
      <circle
        cx="16"
        cy="9"
        data-color="color-2"
        fill="none"
        r="4"
        strokeMiterlimit="10"
      />
    </g>
  </>
)
