import { de, enGB } from 'date-fns/locale'
import { camelizeKeys } from 'humps'
import * as _ from 'lodash'
import { parse } from 'query-string'
import { useRef, useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { ApplicationState } from '../../store'
import { ECPRouteParams } from '../../store/app/types'
import { updateProfileState } from '../../store/profile/actions'
import { configAmplifyInstance } from '../utils'

function useQuery(): ECPRouteParams {
  return camelizeKeys(parse(useLocation().search)) as ECPRouteParams
}

function usePrevious<T>(value?: T) {
  const ref = useRef<T>()

  // Store current value in ref
  useEffect(() => {
    ref.current = value
  }, [value]) // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current
}

function useLoading(): boolean {
  const {
    app: { loadingSelectedStyles, loadingPremiumFeature },
    profile: { loadingProfile, loadingUploadAvatar },
    shopping: {
      loadingMeters,
      loadingOrderAddresses,
      loadingOrderDetailItem,
      loadingOrderIdSelection,
      loadingOrders,
      loadingPricingDetailItem,
      loadingRecentOrders,
      loadingStatus
    }
  } = useSelector((state: ApplicationState) => state)

  return (
    (loadingPremiumFeature as boolean) ||
    (loadingSelectedStyles as boolean) ||
    (loadingProfile as boolean) ||
    (loadingMeters as boolean) ||
    (loadingOrderAddresses as boolean) ||
    (loadingUploadAvatar as boolean) ||
    (loadingOrderDetailItem as boolean) ||
    (loadingOrderIdSelection as boolean) ||
    (loadingOrders as boolean) ||
    (loadingPricingDetailItem as boolean) ||
    (loadingRecentOrders as boolean) ||
    (loadingStatus as boolean)
  )
}

function useLocale(): Locale {
  const { language } = useSelector(
    (state: ApplicationState) => state.profile.profile
  )

  return language === 'de' ? de : enGB
}

function useConfigAmplify() {
  const { test } = useQuery() as ECPRouteParams
  const [cookies] = useCookies([])

  useEffect(() => {
    if (cookies?.amplify_config_userPoolId && process.env.REACT_APP_STAGE) {
      configAmplifyInstance({
        userPoolId: cookies.amplify_config_userPoolId,
        userPoolWebClientId: cookies.amplify_config_userPoolWebClientId,
        identityPoolId: cookies.amplify_config_identityPoolId
      })
    } else {
      // setup local development env
      let tmp

      if (test) {
        localStorage.setItem('test', test)
        tmp = test
      } else {
        tmp = localStorage.getItem('test')
      }
      if (tmp === '1') {
        configAmplifyInstance({
          userPoolId: 'eu-central-1_DewwVWDxW',
          userPoolWebClientId: '55tgsqmak0q7n2kmbv9b7ckfpt',
          identityPoolId: 'eu-central-1:7a8e6b14-5cb1-4fc8-a381-f846b2efd231'
        })
      } else {
        configAmplifyInstance({
          userPoolId: 'eu-central-1_DrwAbcFVV',
          userPoolWebClientId: '4dtopnu2qel8dtl7cb0n19dodn',
          identityPoolId: 'eu-central-1:b7671e82-f076-436f-a5b9-834b54f35c7d'
        })
      }
    }
  }, [cookies, test])
}

function useSetPassword() {
  const [values, setValues] = useState<Record<string, unknown>>({
    password: ''
  })
  const dispatch = useDispatch()
  const [isMinimum8Characters, setIsMinimum8Characters] = useState<boolean>(
    false
  )
  const [
    isOneUpperCaseCharacter,
    setIsOneUpperCaseCharacter
  ] = useState<boolean>(false)
  const [isOneNumber, setIsOneNumber] = useState<boolean>(false)

  const onChangePassword = _.debounce((values: Record<string, unknown>) => {
    setValues(values)
    dispatch(
      updateProfileState({
        changePasswordError: {
          type: null,
          message: ''
        }
      })
    )
  }, 300)

  const [isValidateForm, setIsValidateForm] = useState<boolean>(false)

  useEffect(() => {
    const regCheckMinimum8Characters = /(.{8,})/gm
    const regCheckOneUpperCaseCharacter = /(?=.*[A-Z])/gm
    const regOneNumber = /(?=.*[0-9])/gm

    setIsMinimum8Characters(
      regCheckMinimum8Characters.test(values.password as string)
    )
    setIsOneUpperCaseCharacter(
      regCheckOneUpperCaseCharacter.test(values.password as string)
    )
    setIsOneNumber(regOneNumber.test(values.password as string))
  }, [values])

  useEffect(() => {
    isMinimum8Characters && isOneUpperCaseCharacter && isOneNumber
      ? setIsValidateForm(true)
      : setIsValidateForm(false)
  }, [isMinimum8Characters, isOneUpperCaseCharacter, isOneNumber])

  return {
    isMinimum8Characters,
    isOneNumber,
    isOneUpperCaseCharacter,
    values,
    isValidateForm,
    onChangePassword
  }
}

export {
  useQuery,
  usePrevious,
  useLoading,
  useLocale,
  useConfigAmplify,
  useSetPassword
}
