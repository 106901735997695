import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const BatteryLevelIcon = createSvgIcon(
  <>
    <title>battery level</title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <rect
        fill="none"
        height="25"
        stroke="#222222"
        strokeMiterlimit="10"
        width="20"
        x="6"
        y="6"
      />{' '}
      <polyline
        data-color="color-2"
        fill="none"
        points="12 3 12 1 20 1 20 3"
        strokeMiterlimit="10"
      />{' '}
      <line
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
        x1="11"
        x2="21"
        y1="26"
        y2="26"
      />{' '}
      <line
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
        x1="11"
        x2="21"
        y1="22"
        y2="22"
      />{' '}
      <line
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
        x1="11"
        x2="21"
        y1="18"
        y2="18"
      />
    </g>
  </>
)
