import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const CurrencyEuroIcon = createSvgIcon(
  <>
    <title>currency euro</title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="square"
      strokeLinejoin="miter"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <path
        d="M25,28 c-1.706,0.894-3.551,1-5.3,1C12.52,29,8,23.18,8,16S12.52,3,19.7,3c1.747,0,3.758,0.146,5.3,1"
        fill="none"
        stroke="#222222"
        strokeMiterlimit="10"
      />{' '}
      <line
        fill="none"
        stroke="#222222"
        strokeMiterlimit="10"
        x1="4"
        x2="19"
        y1="13"
        y2="13"
      />{' '}
      <line
        fill="none"
        stroke="#222222"
        strokeMiterlimit="10"
        x1="4"
        x2="19"
        y1="19"
        y2="19"
      />
    </g>
  </>
)
