import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const EpilotLogoIcon = createSvgIcon(
  <>
    <g fill="#039BE5" transform="scale(0.25)">
      <path
        className="st3"
        d="m281 114c0 18.2-4.3 35.3-12 50.5 -2.5 4.9-5.3 9.7-8.5 14.2 -5.7 8-12.4 15.2-19.9 21.5 -4.8 4-10 7.7-15.5 10.8 -13.7 7.9-29.2 13-45.8 14.5 -1.3 0.1-2.6 0.2-4 0.3 1.1-4.8 1.7-9.7 1.7-14.8 0-17.6 9.5-33 23.7-41.4 0.1 0 0.1-0.1 0.2-0.1 0.2-0.1 0.4-0.2 0.6-0.3 4.2-2.5 8-5.4 11.5-8.7 4.2-4 7.9-8.5 10.9-13.5l0 0 0 0c5.8-9.6 9.1-20.9 9.1-33 0-5.1-0.6-10.1-1.7-14.8 -1.4-5.7-3.5-11.2-6.3-16.2 -10.9-19.7-31.9-33-56-33s-45.1 13.3-56 33c-19.5 0-37.9 4.3-54.4 12.1 9-52.9 55-93.1 110.4-93.1s101.4 40.3 110.4 93.1c1.1 6.1 1.6 12.5 1.6 18.9z"
      />
      <path
        className="st3"
        d="m337 211c0 61.9-50.1 112-112 112 -13.7 0-26.8-2.5-38.9-6.9 15.4-10.7 28.3-24.8 37.8-41.1 0.4 0 0.7 0 1.1 0 35.3 0 64-28.6 64-64 0-12.1-3.3-23.4-9.1-33 9.2-15.9 15.1-33.9 16.7-53.2 24.7 20.6 40.4 51.6 40.4 86.2z"
      />
      <path
        className="st3"
        d="m217 114c0 6.3-1.2 12.3-3.4 17.8 -2.2 5.4-5.3 10.3-9.1 14.5 -3.2 3.5-6.9 6.6-11.1 9l0 0c-0.1 0-0.1 0.1-0.2 0.1 -0.2 0.1-0.4 0.2-0.6 0.3 -9.2 5.4-16.9 13-22.4 22.2 -0.4 0.7-0.8 1.3-1.2 2 -5.1 9.2-8 19.8-8 31 0 5.1-0.8 9.9-2.2 14.5 -1.8 5.6-4.5 10.7-8 15.2 -6.8 8.6-16.4 14.8-27.4 17.2 -4.1-8.8-7-18.2-8.7-28 -1.1-6.1-1.6-12.4-1.6-18.9l0 0c0-16.8 3.7-32.6 10.3-46.9 0.6-1.2 1.1-2.4 1.8-3.6 1.9-3.7 4-7.3 6.3-10.8 0.7-1.1 1.5-2.2 2.2-3.3 9.3-13.2 21.4-24.2 35.5-32.3 5.4-3.2 11.2-5.9 17.1-8.1 9-3.4 18.7-5.6 28.6-6.5 1.3 4.6 2.1 9.5 2.1 14.6z"
      />
      <path
        className="st3"
        d="m223.4 229.9c-1.7 9.8-4.6 19.2-8.7 28 -2.3 5-5 9.9-8 14.4 -9.6 14.6-22.5 26.9-37.7 35.7 -5.4 3.2-11.2 5.9-17.1 8.1 -12.1 4.5-25.2 6.9-38.9 6.9 -61.9 0-112-50.1-112-112 0-34.6 15.7-65.6 40.5-86.2 4.8-4 10-7.7 15.5-10.8 14.8-8.6 31.7-13.8 49.7-14.8 2.1-0.1 4.2-0.2 6.3-0.2 3.5 0 6.9 0.2 10.2 0.5 10 0.9 19.6 3.1 28.6 6.5 -10.4 7.2-19.6 16-27.5 25.9 -3.8 4.8-7.2 9.9-10.3 15.2l0 0c-0.4 0-0.7 0-1.1 0 -17 0-32.5 6.6-44 17.5l0 0c-4.2 4-7.9 8.5-10.9 13.5 -5.8 9.6-9.1 20.9-9.1 33 0 35.4 28.7 64 64 64 0.4 0 0.8 0 1.1 0 6-0.1 11.7-1 17.2-2.7 16.2-4.8 29.7-15.8 37.7-30.3l0 0c6.2 0 12.3-0.4 18.3-1.3 12.8-1.9 25-5.6 36.2-10.9z"
      />
      <path
        className="st3"
        d="m889 103c-39.8 0-72 32.2-72 72s32.2 72 72 72c39.8 0 72-32.2 72-72s-32.2-72-72-72zm0 120c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48 -21.5 48-48 48z"
      />
      <path
        className="st3"
        d="m625 103c-18.4 0-35.3 6.9-48 18.3 -14.7 13.2-24 32.3-24 53.7l0 120 24 0 0-66.3c12.7 11.4 29.6 18.3 48 18.3 39.8 0 72-32.2 72-72s-32.2-72-72-72zm0 120c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48 -21.5 48-48 48z"
      />
      <rect className="st3" height="144" width="24" x="721" y="103" />
      <rect className="st3" height="192" width="24" x="769" y="55" />
      <circle className="st3" cx="733" cy="79" r="16" />
      <path
        className="st3"
        d="m1039 242.2c-5.3 3.1-11.4 4.8-18 4.8 -4.2 0-8.2-0.7-12-2 -14-4.9-24-18.3-24-34l0-108 -16 0 0-24 16 0 0-24 24 0 0 24 24 0 0 24 -24 0 0 108c0 6.6 5.4 12 12 12 2.2 0 4.2-0.6 6-1.6l12 20.8z"
      />
      <path
        className="st3"
        d="m529 175c0-4.1-0.3-8.1-1-12 -5.7-34-35.3-60-71-60s-65.3 26-71 60c-0.7 3.9-1 7.9-1 12s0.3 8.1 1 12c1.4 8.6 4.4 16.7 8.6 24 12.5 21.5 35.7 36 62.4 36s49.9-14.5 62.4-36l-30.6 0c-8.5 7.5-19.6 12-31.7 12s-23.3-4.5-31.7-12c-7.1-6.2-12.3-14.6-14.8-24l117.4 0c0.7-3.9 1-7.9 1-12zm-118.5-12c5.3-20.7 24.1-36 46.5-36s41.2 15.3 46.5 36l-93 0z"
      />
    </g>
  </>
)
