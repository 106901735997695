import { makeStyles } from '@material-ui/core'
import React, { SVGAttributes } from 'react'

export interface SvgIconProps extends SVGAttributes<SVGSVGElement> {
  /**
   * 'smallest' | 'small' | 'normal' | 'large' | number
   */
  size?: IconSize
}
type IconSize = 'smallest' | 'small' | 'normal' | 'large' | number
type IconStyle = {
  color?: string
  size?: number
}

const useStyle = makeStyles({
  propStyle: (props: IconStyle) => ({
    color: props.color,
    width: `${props.size}px`,
    height: `${props.size}px`,
    '& *[stroke]': {
      stroke: props.color,
      '&[stroke=none]': {
        stroke: 'none'
      }
    }
  })
})

const getIconSize = (size: IconSize): number => {
  if (Number(size)) return Number(size)
  switch (size) {
    case 'smallest':
      return 16
    case 'small':
      return 24
    case 'normal':
      return 32
    case 'large':
      return 64
    default:
      return 32
  }
}

export const createSvgIcon = (svgContent: React.ReactElement) => (
  props: SvgIconProps
): React.ReactElement => {
  const {
    size = 'normal',
    color = '#222222',
    strokeWidth = 1,
    ...restProps
  } = props
  const classes = useStyle({ size: getIconSize(size), color })

  return (
    <svg
      viewBox="0 0 32 32"
      {...restProps}
      className={classes.propStyle}
      strokeWidth={strokeWidth}
      xmlns="http://www.w3.org/2000/svg"
    >
      {svgContent}
    </svg>
  )
}
