import { CognitoUserSession } from 'amazon-cognito-identity-js'
import { Amplify, API, Auth } from 'aws-amplify'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { camelizeKeys, decamelizeKeys } from 'humps'

import {
  IVY_SERVER_API_URL,
  IvyServerAPIName,
  PORTAL_API_URL,
  PORTAL_COGNITO_TRIGGER_API_URL,
  PORTAL_METER_READING_API_URL,
  PortalAPIName,
  PortalCognitoTriggerAPIName,
  PortalMeterReadingAPIName,
  STYLING_EDITOR_API_URL,
  StylingEditorAPIName
} from '../constants'

export const PORTAL_API_URL_ECP_ADMIN =
  process.env.NODE_ENV === 'development'
    ? 'https://4njbo15nu6.execute-api.eu-central-1.amazonaws.com/dev/v1'
    : process.env.REACT_APP_PORTAL_API_URL_ECP_ADMIN

export interface Endpoints {
  name: string
  endpoint: string
}

interface AmplifyArgument {
  path: string
  apiName: string
  params?: Record<string, unknown>
  config?: Record<string, unknown>
  customHeader?: Record<string, unknown>
}

let amplifyApi: typeof API | null = null

const DEFAULT_ENDPOINTS = [
  {
    name: IvyServerAPIName,
    endpoint: IVY_SERVER_API_URL
  },
  {
    name: PortalAPIName,
    endpoint: PORTAL_API_URL
  },
  {
    name: StylingEditorAPIName,
    endpoint: STYLING_EDITOR_API_URL
  },
  {
    name: PortalMeterReadingAPIName,
    endpoint: PORTAL_METER_READING_API_URL
  },
  {
    name: PortalCognitoTriggerAPIName,
    endpoint: PORTAL_COGNITO_TRIGGER_API_URL
  }
] as Endpoints[]

export const configAmplifyInstance = ({
  userPoolId,
  userPoolWebClientId,
  identityPoolId
}: {
  userPoolId: string
  userPoolWebClientId: string
  identityPoolId: string
}) => {
  Amplify.configure({
    Auth: {
      region: 'eu-central-1',
      userPoolId,
      userPoolWebClientId,
      identityPoolId
    }
  })
  initializeDefaultAmplifyEndpoints()
}

const initializeDefaultAmplifyEndpoints = () => {
  const amplifyEndpoints = [] as Record<string, unknown>[]

  DEFAULT_ENDPOINTS.map((item: Endpoints) => {
    const currentEndpoint = {
      name: item.name,
      region: 'eu-central-1',
      endpoint: item.endpoint,
      custom_header: () => {
        return Auth.currentSession()
          .then((session: CognitoUserSession) => {
            return {
              Authorization:
                'Bearer ' +
                btoa(
                  JSON.stringify({
                    idtoken: session?.getIdToken()?.getJwtToken(),
                    accesstoken: session?.getAccessToken()?.getJwtToken()
                  })
                )
            }
          })
          .catch((error: Error) => console.log(error))
      }
    }

    amplifyEndpoints.push(currentEndpoint)
  })
  Amplify.configure({
    API: {
      endpoints: amplifyEndpoints
    }
  })
  amplifyApi = API
}

export function amplifyGet({
  path,
  apiName,
  params = {},
  config = {},
  customHeader = {}
}: AmplifyArgument) {
  return amplifyApi
    ?.get(apiName, path, {
      headers: {
        Accept: 'application/json',
        ...customHeader
      },
      ...config,
      queryStringParameters: decamelizeKeys(params)
    })
    .then((response: AxiosResponse) => camelizeKeys(response))
    .catch((error: AxiosError) => {
      console.log(error)

      throw error
    })
}

export function amplifyPost({
  path,
  apiName,
  params = {},
  config = {},
  customHeader = {}
}: AmplifyArgument) {
  return amplifyApi
    ?.post(apiName, path, {
      headers: {
        Accept: 'application/json',
        ...customHeader
      },
      ...config,
      body: params
    })
    .then((response: AxiosResponse) => camelizeKeys(response))
    .catch((error: AxiosError) => {
      console.log(error)

      throw error
    })
}

export function amplifyPut({
  path,
  apiName,
  params = {},
  config = {},
  customHeader = {}
}: AmplifyArgument) {
  return amplifyApi
    ?.put(apiName, path, {
      headers: {
        Accept: 'application/json',
        ...customHeader
      },
      ...config,
      body: params
    })
    .then((response: AxiosResponse) => camelizeKeys(response))
    .catch((error: AxiosError) => {
      console.log(error)

      throw error
    })
}

export function amplifyPatch({
  path,
  apiName,
  params = {},
  config = {},
  customHeader = {}
}: AmplifyArgument) {
  return amplifyApi
    ?.patch(apiName, path, {
      headers: {
        Accept: 'application/json',
        ...customHeader
      },
      ...config,
      body: params
    })
    .then((response: AxiosResponse) => camelizeKeys(response))
    .catch((error: AxiosError) => {
      console.log(error)

      throw error
    })
}

export function amplifyDel({
  path,
  apiName,
  config = {},
  customHeader = {}
}: AmplifyArgument) {
  return amplifyApi
    ?.del(apiName, path, {
      headers: {
        Accept: 'application/json',
        ...customHeader
      },
      ...config
    })
    .then((response: AxiosResponse) => camelizeKeys(response))
    .catch((error: AxiosError) => {
      console.log(error)

      throw error
    })
}

export const get = (url: string, params = {}) => {
  return axios
    .get(url, { params })
    .then((response) => response.data)
    .catch((error) => {
      return { error }
    })
}
