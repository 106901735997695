import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const DevIcon = createSvgIcon(
  <>
    <title>dev</title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="square"
      strokeLinejoin="miter"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <rect
        fill="none"
        height="30"
        stroke="#222222"
        strokeMiterlimit="10"
        width="26"
        x="3"
        y="1"
      />{' '}
      <polyline
        data-color="color-2"
        fill="none"
        points=" 11,13 8,16 11,19 "
        strokeMiterlimit="10"
      />{' '}
      <polyline
        data-color="color-2"
        fill="none"
        points=" 21,19 24,16 21,13 "
        strokeMiterlimit="10"
      />{' '}
      <line
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
        x1="14"
        x2="18"
        y1="22"
        y2="10"
      />
    </g>
  </>
)
