import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const CommissioningPowerIcon = createSvgIcon(
  <>
    <title></title>
    <g className="nc-icon-wrapper" fill="none">
      <rect
        height="30"
        rx="2"
        stroke="#222222"
        transform="rotate(-.342 2 1)"
        width="28"
        x="2"
        y="1"
      />
      <path
        d="M7.744 20.675l.849.84 1.77-1.793"
        stroke="#222222"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.774 25.675l.849.84 1.77-1.793"
        stroke="#222222"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M13.509 20.595l11-.066" stroke="#222222" />
      <path d="M13.539 25.595l11-.066" stroke="#222222" />
      <path
        clipRule="evenodd"
        d="M18.552 3.987l-5.96 5.514a.087.087 0 0 0 .023.144l2.072.933c.04.017.06.062.048.104l-1.48 5.08a.088.088 0 0 0 .143.09l6.005-5.449a.087.087 0 0 0-.02-.143l-2.08-1.018a.087.087 0 0 1-.046-.103l1.439-5.064a.087.087 0 0 0-.144-.088z"
        fillRule="evenodd"
        stroke="#222222"
      />
    </g>
  </>
)
