import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const FileContentIcon = createSvgIcon(
  <>
    <title>file content</title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="square"
      strokeLinejoin="miter"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <polyline
        data-cap="butt"
        fill="none"
        points="21,1 21,9 29,9 "
        stroke="#222222"
        strokeLinecap="butt"
        strokeMiterlimit="10"
      />{' '}
      <polygon
        fill="none"
        points="21,1 3,1 3,31 29,31 29,9 "
        stroke="#222222"
        strokeMiterlimit="10"
      />{' '}
      <line
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
        x1="9"
        x2="23"
        y1="22"
        y2="22"
      />{' '}
      <line
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
        x1="9"
        x2="23"
        y1="16"
        y2="16"
      />{' '}
      <line
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
        x1="9"
        x2="15"
        y1="10"
        y2="10"
      />
    </g>
  </>
)
