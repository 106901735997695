import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const EditFileIcon = createSvgIcon(
  <>
    <title>edit file</title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="square"
      strokeLinejoin="miter"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <polyline
        fill="none"
        points="8 31 3 31 3 1 29 1 29 10"
        stroke="#222222"
        strokeMiterlimit="10"
      />
      <polygon
        data-color="color-2"
        fill="none"
        points="19 28 13 30 15 24 26 13 30 17 19 28"
        strokeMiterlimit="10"
      />
    </g>
  </>
)
