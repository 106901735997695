export enum ProfileActionTypes {
  GET_PROFILE_REQUEST = '@@profile/GET_PROFILE_REQUEST',
  GET_PROFILE_SUCCESS = '@@profile/GET_PROFILE_SUCCESS',
  GET_PROFILE_FAILURE = '@@profile/GET_PROFILE_FAILURE',
  UPDATE_PROFILE_INFO = '@@profile/UPDATE_PROFILE_INFO',
  UPDATE_PROFILE_STATE = '@@profile/UPDATE_PROFILE_STATE',

  UPDATE_SUB_REQUEST = '@@profile/UPDATE_SUB_REQUEST',
  UPDATE_SUB_SUCCESS = '@@profile/UPDATE_SUB_SUCCESS',
  UPDATE_SUB_FAILURE = '@@profile/UPDATE_SUB_FAILURE',

  UPDATE_LANGUAGE_REQUEST = '@@profile/UPDATE_LANGUAGE_REQUEST',
  UPDATE_LANGUAGE_SUCCESS = '@@profile/UPDATE_LANGUAGE_SUCCESS',
  UPDATE_LANGUAGE_FAILURE = '@@profile/UPDATE_LANGUAGE_FAILURE',

  UPLOAD_AVATAR_REQUEST = '@@profile/UPLOAD_AVATAR_REQUEST',
  UPLOAD_AVATAR_SUCCESS = '@@profile/UPLOAD_AVATAR_SUCCESS',
  UPLOAD_AVATAR_FAILURE = '@@profile/UPLOAD_AVATAR_FAILURE',

  DELETE_AVATAR_REQUEST = '@@profile/DELETE_AVATAR_REQUEST',
  DELETE_AVATAR_SUCCESS = '@@profile/DELETE_AVATAR_SUCCESS',
  DELETE_AVATAR_FAILURE = '@@profile/DELETE_AVATAR_FAILURE',

  DELETE_USER_ACCOUNT_REQUEST = '@@profile/DELETE_USER_ACCOUNT_REQUEST',
  DELETE_USER_ACCOUNT_SUCCESS = '@@profile/DELETE_USER_ACCOUNT_SUCCESS',
  DELETE_USER_ACCOUNT_FAILURE = '@@profile/DELETE_USER_ACCOUNT_FAILURE',

  CHANGE_PASSWORD_REQUEST = '@@profile/CHANGE_PASSWORD_REQUEST',
  CHANGE_PASSWORD_SUCCESS = '@@profile/CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAILURE = '@@profile/CHANGE_PASSWORD_FAILURE'
}

export type Address = {
  id?: string
  name?: string
  streetName?: string
  streetNumber?: string
  postcode?: string
  city?: string
  country?: string
  is_billing?: boolean
  is_delivery?: boolean
}

export type Profile = {
  firstName: string
  lastName: string
  company_name?: string
  email: string
  phone: string
  subscription: boolean
  language: string
  avatar: string
  addresses: Address[]
  customerId: number | null
  organizationId: string | null
  brandId: string
}

export interface ProfileState {
  changePasswordError: {
    type: ChangePasswordErrorType | null
    message: string
  }
  isShowChangePasswordSuccessMessage: boolean
  isShowDeleteUserAccountMessage: boolean
  isShowChangeLanguageSuccessMessage: boolean
  loadingProfile: boolean
  loadingSubscription: boolean
  loadingUploadAvatar: boolean
  loadingDeleteUserAccount: boolean
  loadingChangePassword: boolean
  loadingUpdateLanguage: boolean
  profile: Profile
}

export enum ChangePasswordErrorType {
  WRONG_CURRENT_PASSWORD = 'NotAuthorizedException',
  LIMIT_EXCEEDED = 'LimitExceededException',
  INVALID_PARAMETER = 'InvalidParameterException'
}
