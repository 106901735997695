export const LANDING_PAGE = '/'
export const SIGN_UP_PAGE = '/signup'
export const SIGN_IN_PAGE = '/signin'
export const CREATE_ACCOUNT_PAGE = '/create-account'
export const FORGOT_PASSWORD_PAGE = '/forgot-password'
export const DASHBOARD_PAGE = '/dashboard'
export const PROFILE_PAGE = '/profile'
export const ORDERS_PAGE = '/orders'
export const ORDER_DETAIL_PAGE = '/order-detail'
export const PROFILE_DETAIL_PAGE = '/profile-detail'
export const ADDRESS_PAGE = '/address'
export const SUBSCRIPTION_PAGE = '/subscription'
export const LANGUAGE_PAGE = '/language'
export const RESET_PASSWORD_PAGE = '/reset-password'
export const CHANGE_PASSWORD_PAGE = '/change-password'
export const METERS_PAGE = '/meters'
export const ERROR_PAGE = '/error'
export const ACTIVATION_PENDING_PAGE = '/activation-pending'
export const ACTIVATE_ACCOUNT_PAGE = '/activate-account'
export const SET_PASSWORD_PAGE = '/set-password'
