import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const FilterIcon = createSvgIcon(
  <>
    <title></title>
    <g className="nc-icon-wrapper" fill="#222222">
      <g
        fill="none"
        fillRule="evenodd"
        id="_Icon-/-Others-/-filter-/-Black"
        stroke="none"
        strokeWidth="1"
      >
        <g id="filter" stroke="#222222" transform="translate(6 8)">
          <path
            d="M12.72 15.484H7.338"
            id="Shape"
            transform="rotate(-180 10.029 15.484)"
          />
          <path
            d="M16.236 8.09H3.849"
            id="Shape"
            transform="rotate(-180 10.042 8.09)"
          />
          <path
            d="M19.847.693H.213"
            id="Shape"
            transform="rotate(-180 10.03 .693)"
          />
        </g>
      </g>
    </g>
  </>
)
