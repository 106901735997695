import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const DocxIcon = createSvgIcon(
  <>
    <title>docx</title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="square"
      strokeLinejoin="miter"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <polyline
        data-cap="butt"
        fill="none"
        points="20,1 20,9 28,9 "
        stroke="#222222"
        strokeLinecap="butt"
        strokeMiterlimit="10"
      />{' '}
      <polyline
        fill="none"
        points="28,13 28,9 20,1 4,1 4,13 "
        stroke="#222222"
        strokeMiterlimit="10"
      />{' '}
      <polyline
        fill="none"
        points="4,27 4,31 28,31 28,27 "
        stroke="#222222"
        strokeMiterlimit="10"
      />{' '}
      <path
        d="M9.9,20c0,1-0.3,1.7-0.8,2.2S7.8,23,6.9,23H5v-5.7h2c0.9,0,1.6,0.2,2.1,0.7 S9.9,19.1,9.9,20z M8.3,20.1c0-0.5-0.1-0.9-0.3-1.2S7.4,18.5,7,18.5H6.5v3.2h0.3c0.5,0,0.8-0.1,1-0.4S8.3,20.6,8.3,20.1z"
        data-color="color-2"
        data-stroke="none"
        stroke="none"
        strokeLinecap="square"
        strokeLinejoin="miter"
      />{' '}
      <path
        d="M16.2,20.1c0,1-0.2,1.7-0.7,2.2s-1.2,0.8-2.1,0.8c-0.9,0-1.6-0.3-2.1-0.8 s-0.7-1.2-0.7-2.2c0-1,0.2-1.7,0.7-2.2s1.2-0.8,2.1-0.8c0.9,0,1.6,0.2,2.1,0.7S16.2,19.2,16.2,20.1z M12.3,20.1 c0,1.1,0.4,1.7,1.2,1.7c0.4,0,0.7-0.1,0.9-0.4s0.3-0.7,0.3-1.3c0-0.6-0.1-1-0.3-1.3s-0.5-0.4-0.9-0.4C12.7,18.5,12.3,19,12.3,20.1z"
        data-color="color-2"
        data-stroke="none"
        stroke="none"
        strokeLinecap="square"
        strokeLinejoin="miter"
      />{' '}
      <path
        d="M19.8,18.5c-0.4,0-0.7,0.2-0.9,0.5s-0.3,0.7-0.3,1.2c0,1.1,0.4,1.6,1.3,1.6 c0.3,0,0.5,0,0.7-0.1s0.5-0.2,0.7-0.3v1.3c-0.5,0.2-1,0.3-1.6,0.3c-0.9,0-1.5-0.2-2-0.8S17,21.1,17,20.2c0-0.6,0.1-1.1,0.3-1.6 s0.5-0.8,1-1s0.9-0.4,1.5-0.4c0.6,0,1.2,0.1,1.8,0.4l-0.5,1.2c-0.2-0.1-0.4-0.2-0.6-0.2S20,18.5,19.8,18.5z"
        data-color="color-2"
        data-stroke="none"
        stroke="none"
        strokeLinecap="square"
        strokeLinejoin="miter"
      />{' '}
      <path
        d="M27.6,23h-1.8l-1.1-1.8L23.6,23h-1.7l1.9-2.9L22,17.3h1.7l1,1.8l1-1.8h1.8 l-1.8,2.9L27.6,23z"
        data-color="color-2"
        data-stroke="none"
        stroke="none"
        strokeLinecap="square"
        strokeLinejoin="miter"
      />{' '}
      <rect
        fill="none"
        height="14"
        stroke="#222222"
        strokeMiterlimit="10"
        width="30"
        x="1"
        y="13"
      />
    </g>
  </>
)
