import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const DownloadIcon = createSvgIcon(
  <>
    <title>download</title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="square"
      strokeLinejoin="miter"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <line
        data-cap="butt"
        fill="none"
        stroke="#222222"
        strokeLinecap="butt"
        strokeMiterlimit="10"
        x1="16"
        x2="16"
        y1="14"
        y2="30"
      />{' '}
      <polyline
        fill="none"
        points="21,25 16,30 11,25 "
        stroke="#222222"
        strokeMiterlimit="10"
      />{' '}
      <path
        d="M20,21h6 c2.8-0.3,5-2.6,5-5.5c0-2.9-2.2-5.2-5-5.5C25.5,5,21.2,1,16,1c-4.5,0-8.3,3-9.6,7.1C3.4,8.6,1,11.3,1,14.5c0,3.4,2.6,6.2,6,6.5h5"
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
      />
    </g>
  </>
)
