import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const FileUploadIcon = createSvgIcon(
  <>
    <title>file upload</title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <line
        data-cap="butt"
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
        x1="16"
        x2="16"
        y1="15"
        y2="2"
      />
      <polyline
        fill="none"
        points="22 11 26 11 31 20 31 30 1 30 1 20 6 11 10 11"
        stroke="#222222"
        strokeMiterlimit="10"
      />
      <polyline
        data-color="color-2"
        fill="none"
        points="11 7 16 2 21 7"
        strokeMiterlimit="10"
      />
      <polyline
        data-cap="butt"
        fill="none"
        points="31 20 21 20 21 23 11 23 11 20 1 20"
        stroke="#222222"
        strokeMiterlimit="10"
      />
    </g>
  </>
)
