import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const ArrowLeftIcon = createSvgIcon(
  <>
    <title></title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="square"
      strokeLinejoin="miter"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <polyline
        fill="none"
        points="23,30 9,16 23,2 "
        stroke="#222222"
        strokeMiterlimit="10"
        transform="translate(0, 0)"
      />
    </g>
  </>
)
