import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const CustomerSupportIcon = createSvgIcon(
  <>
    <title>customer support</title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="square"
      strokeLinejoin="miter"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <polygon
        data-color="color-2"
        fill="none"
        points=" 17,2 17,15 22,11 31,11 31,2 "
        strokeMiterlimit="10"
      />{' '}
      <circle
        cx="9"
        cy="14"
        fill="none"
        r="4"
        stroke="#222222"
        strokeMiterlimit="10"
      />{' '}
      <path
        d="M17,30H1v-4.169 c0-1.229,0.739-2.345,1.885-2.787C4.217,22.53,6.256,22,9,22s4.783,0.53,6.115,1.044C16.261,23.486,17,24.602,17,25.831V30z"
        fill="none"
        stroke="#222222"
        strokeMiterlimit="10"
      />
    </g>
  </>
)
