import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const Box3dIcon = createSvgIcon(
  <>
    <title></title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="square"
      strokeLinejoin="miter"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <line
        data-cap="butt"
        data-color="color-2"
        fill="none"
        strokeLinecap="butt"
        strokeMiterlimit="10"
        x1="8.471"
        x2="24.137"
        y1="5.265"
        y2="13.431"
      />{' '}
      <polyline
        data-cap="butt"
        fill="none"
        points="31,10 17,17 1,9 "
        stroke="#222222"
        strokeLinecap="butt"
        strokeMiterlimit="10"
      />{' '}
      <line
        data-cap="butt"
        fill="none"
        stroke="#222222"
        strokeLinecap="butt"
        strokeMiterlimit="10"
        x1="17"
        x2="17"
        y1="17"
        y2="30"
      />{' '}
      <polygon
        fill="none"
        points="31,10 31,23 17,30 1,22 1,9 15,2 "
        stroke="#222222"
        strokeMiterlimit="10"
      />
    </g>
  </>
)
