import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const CartRemoveIcon = createSvgIcon(
  <>
    <title>cart remove</title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="square"
      strokeLinejoin="miter"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <polyline
        data-cap="butt"
        fill="none"
        points="29.4,11.1 28,21 8,21 5,1 1,1 "
        stroke="#222222"
        strokeLinecap="butt"
        strokeMiterlimit="10"
      />{' '}
      <line
        data-cap="butt"
        fill="none"
        stroke="#222222"
        strokeLinecap="butt"
        strokeMiterlimit="10"
        x1="5.8"
        x2="19.1"
        y1="6"
        y2="6"
      />{' '}
      <circle
        cx="25"
        cy="7"
        data-color="color-2"
        fill="none"
        r="6"
        strokeMiterlimit="10"
      />{' '}
      <line
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
        x1="23"
        x2="27"
        y1="7"
        y2="7"
      />{' '}
      <circle
        cx="10"
        cy="28"
        data-color="color-2"
        fill="none"
        r="3"
        strokeMiterlimit="10"
      />{' '}
      <circle
        cx="27"
        cy="28"
        data-color="color-2"
        fill="none"
        r="3"
        strokeMiterlimit="10"
      />
    </g>
  </>
)
