import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const DotsIcon = createSvgIcon(
  <>
    <title></title>
    <g className="nc-icon-wrapper" fill="#222222">
      <g
        fill="none"
        fillRule="evenodd"
        id="icons/dots"
        stroke="none"
        strokeWidth="1"
      >
        <circle cx="6" cy="17" fill="#222222" id="Oval-2" r="2" />
        <circle cx="16" cy="17" fill="#222222" id="Oval-2-Copy" r="2" />
        <circle cx="26" cy="17" fill="#222222" id="Oval-2-Copy-2" r="2" />
      </g>
    </g>
  </>
)
