export enum AppActionTypes {
  SET_SESSION_ID = '@@app/SET_SESSION_ID',
  SET_LANGUAGE_ID = '@@app/SET_LANGUAGE_ID',
  SET_PUBLIC_DATA = '@@app/SET_PUBLIC_DATA',

  GET_SELECTED_STYLES_REQUEST = '@@app/GET_SELECTED_STYLES_REQUEST',
  GET_SELECTED_STYLES_SUCCESS = '@@app/GET_SELECTED_STYLES_SUCCESS',
  GET_SELECTED_STYLES_FAILURE = '@@app/GET_SELECTED_STYLES_FAILURE',

  CHECK_EMAIL_EXIST_REQUEST = '@@app/CHECK_EMAIL_EXIST_REQUEST',
  CHECK_EMAIL_EXIST_SUCCESS = '@@app/CHECK_EMAIL_EXIST_SUCCESS',
  CHECK_EMAIL_EXIST_FAILURE = '@@app/CHECK_EMAIL_EXIST_FAILURE',

  AUTHENTICATION_REQUEST = '@@app/AUTHENTICATION_REQUEST',
  AUTHENTICATION_SUCCESS = '@@app/AUTHENTICATION_SUCCESS',
  AUTHENTICATION_FAILURE = '@@app/AUTHENTICATION_FAILURE',

  CHECK_USER_LOGGED_IN_REQUEST = '@@app/CHECK_USER_LOGGED_IN_REQUEST',
  CHECK_USER_LOGGED_IN_SUCCESS = '@@app/CHECK_USER_LOGGED_IN_SUCCESS',
  CHECK_USER_LOGGED_IN_FAILURE = '@@app/CHECK_USER_LOGGED_IN_FAILURE',

  CHECK_PREMIUM_FEATURE_REQUEST = '@@app/CHECK_PREMIUM_FEATURE_REQUEST',
  CHECK_PREMIUM_FEATURE_SUCCESS = '@@app/CHECK_PREMIUM_FEATURE_SUCCESS',
  CHECK_PREMIUM_FEATURE_FAILURE = '@@app/CHECK_PREMIUM_FEATURE_FAILURE',

  UPDATE_AUTHENTICATION_STATUS = '@@app/UPDATE_AUTHENTICATION_STATUS',
  UPDATE_APP_STATE = '@@app/UPDATE_APP_STATE',
  LOG_OUT = '@@app/LOG_OUT',

  GET_TERMS_PRIVACY_AND_SUPPORT_EMAIL_REQUEST = '@@app/GET_TERMS_PRIVACY_AND_SUPPORT_EMAIL_REQUEST',
  GET_TERMS_PRIVACY_AND_SUPPORT_EMAIL_SUCCESS = '@@app/GET_TERMS_PRIVACY_AND_SUPPORT_EMAIL_SUCCESS',
  GET_TERMS_PRIVACY_AND_SUPPORT_EMAIL_FAILURE = '@@app/GET_TERMS_PRIVACY_AND_SUPPORT_EMAIL_FAILURE'
}

export type ECPRouteParams = {
  brandId?: string
  test?: string
  orgId?: string
}

export type SelectedStyles = {
  id: string
  styleName: string
  styleType: number
  logo: {
    name: string
    url: string
  }
  palette: {
    background?: string
    primary?: string
  }
  typography: {
    primaryColor?: string
  }
}

export type TermsPrivacyAndEmail = {
  id?: number
  terms_privacy?: string
  support_email?: string
}

export interface AppState {
  loadingSelectedStyles?: boolean | null
  loadingPremiumFeature?: boolean
  selectedStyles?: SelectedStyles
  currEmail: string
  loggedIn: boolean
  isAuthenticating?: boolean
  isLoggingIn?: boolean
  isAlreadyCheckedUserLoggedIn?: boolean
  isAlreadyCheckedPremiumFeature?: boolean
  isAlreadyLoadedSelectedStyles?: boolean
  auth: {
    errorMessage: string
    isEmailExistInEpilotSystem?: boolean | null
  }
  termsPrivacyAndSupportEmail: TermsPrivacyAndEmail
  isLoadingTermsPrivacyAndSupportEmail: boolean | null
}
