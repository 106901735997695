import { useTheme } from '@material-ui/core'
import React from 'react'
import { ThemeProvider as SCThemeProvider } from 'styled-components'

import { ThemeProps } from '../themes'

import { LoadingIconContainer } from './style'

export interface LoadingIconProps {
  /**
   * Width for the loading icon
   * */
  width: string
  /**
   * Height for the loading icon
   * */
  height: string
  /**
   * Color for the loading icon
   * */
  color?: string
}

export const LoadingIcon = (props: LoadingIconProps): React.ReactElement => {
  const theme = useTheme<ThemeProps>()
  const { width, height, color } = props

  return (
    <SCThemeProvider theme={theme}>
      <LoadingIconContainer color={color} height={height} width={width} />
    </SCThemeProvider>
  )
}
