import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const ForwardIcon = createSvgIcon(
  <>
    <title></title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <polygon
        fill="none"
        points="30,16 16,5 16,12 2,12 2,20 16,20 16,27 "
        stroke="#222222"
        strokeMiterlimit="10"
        transform="translate(0, 0)"
      />
    </g>
  </>
)
