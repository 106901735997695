import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const CurrencyDollarIcon = createSvgIcon(
  <>
    <title>currency dollar</title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="square"
      strokeLinejoin="miter"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <line
        fill="none"
        stroke="#222222"
        strokeMiterlimit="10"
        x1="15"
        x2="15"
        y1="1"
        y2="31"
      />{' '}
      <path
        d="M21.998,6.34 C18.168,4.62,8,3.844,8,10c0,7.678,14,4.689,14,11.463s-8.864,6.266-15,4.065"
        fill="none"
        stroke="#222222"
        strokeMiterlimit="10"
      />
    </g>
  </>
)
