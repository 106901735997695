import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const ContactsIcon = createSvgIcon(
  <>
    <title>contacts</title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="square"
      strokeLinejoin="miter"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <line
        fill="none"
        stroke="#222222"
        strokeMiterlimit="10"
        x1="7"
        x2="7"
        y1="1"
        y2="31"
      />{' '}
      <path
        d="M2,1h22.909 C26.064,1,27,1.895,27,3v26c0,1.105-0.936,2-2.091,2H2V1z"
        fill="none"
        stroke="#222222"
        strokeMiterlimit="10"
      />{' '}
      <line
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
        x1="31"
        x2="31"
        y1="5"
        y2="9"
      />{' '}
      <line
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
        x1="31"
        x2="31"
        y1="13"
        y2="17"
      />{' '}
      <circle
        cx="17"
        cy="12"
        data-color="color-2"
        fill="none"
        r="2"
        strokeMiterlimit="10"
      />{' '}
      <path
        d="M22,21H12v0 c0-2.209,1.791-4,4-4h2C20.209,17,22,18.791,22,21L22,21z"
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
      />
    </g>
  </>
)
