import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const DeliveryIcon = createSvgIcon(
  <>
    <title>delivery</title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="square"
      strokeLinejoin="miter"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <polyline
        data-cap="butt"
        fill="none"
        points="26,26 31,26 31,18 27,16 24,8 15,8 15,26 18,26 "
        stroke="#222222"
        strokeLinecap="butt"
        strokeMiterlimit="10"
      />{' '}
      <circle
        cx="22"
        cy="26"
        fill="none"
        r="4"
        stroke="#222222"
        strokeMiterlimit="10"
      />{' '}
      <polyline
        fill="none"
        points="19,12 19,16 23,16 "
        stroke="#222222"
        strokeMiterlimit="10"
      />{' '}
      <line
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
        x1="11"
        x2="3"
        y1="8"
        y2="8"
      />{' '}
      <polyline
        fill="none"
        points="1,3 15,3 15,8 "
        stroke="#222222"
        strokeMiterlimit="10"
      />{' '}
      <line
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
        x1="11"
        x2="5"
        y1="13"
        y2="13"
      />{' '}
      <line
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
        x1="11"
        x2="7"
        y1="18"
        y2="18"
      />
    </g>
  </>
)
