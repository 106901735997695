import hexToRgba from 'hex-to-rgba'
import styled, { keyframes } from 'styled-components'

type LoadingIconStyledProps = {
  width: string
  height: string
  color: string | undefined
}

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`

export const LoadingIconContainer = styled.div<LoadingIconStyledProps>`
  border: 2px solid
    ${(props) =>
      props.color ? hexToRgba(props.color, 0.4) : 'rgba(83, 199, 255, 0.4)'};
  border-top: 2px solid ${(props) => (props.color ? props.color : '#53c7ff')};
  border-radius: 50%;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  animation: ${spin} 2s linear infinite;
`
