import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const HandoverSmallIcon = createSvgIcon(
  <>
    <title></title>
    <g className="nc-icon-wrapper" fill="#222222">
      <g
        fill="none"
        fillRule="evenodd"
        id="_Icon-/-Others-/-handover-/-Black"
        stroke="none"
        strokeWidth="4"
      >
        <g id="handover" stroke="#222222" transform="translate(1 1)">
          <path
            d="M5.329 19.956c-3.506 2.908-1.436 4.45.758 6.605 2.166 2.236 3.761 4.402 7.431-.173 7.31 1.548 8.815-2.05 15.603-8.73 1.315-1.294 1.41-3.376.778-4.22-.717-.958-2.286-.826-2.946-.826-.66 0 1.618-1.021.29-3.247-1.326-1.665-2.52-.774-2.677-1.062-.224-.415.98-1.637-.224-3.106-1.206-1.47-2.875-.51-3.313-.696 0-.514.577-2.049-.844-3.076-1.42-1.028-2.337-.395-4.215.972"
            id="Path-2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M18.636 26.694c3.166 3.483 4.282 2.938 7.117-.077 2.835-3.016 3.873-3.744 1.112-6.35"
            id="Path-12"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M26.964 12.312l-.427.856"
            id="Path-13"
            strokeLinecap="round"
            strokeLinejoin="round"
            transform="rotate(4 26.75 12.74)"
          />
          <path
            d="M24.523 7.974l-.547 1.132"
            id="Path-13-Copy"
            strokeLinecap="round"
            strokeLinejoin="round"
            transform="rotate(4 24.25 8.54)"
          />
          <path
            d="M21.019 4.285l-.235.681"
            id="Path-13-Copy"
            strokeLinecap="round"
            strokeLinejoin="round"
            transform="rotate(4 20.902 4.625)"
          />
          <path
            d="M3.458 14.184a2.318 2.318 0 0 0 0 4.636h3.757a2.318 2.318 0 0 0 0-4.636H3.458z"
            id="Rectangle"
            transform="rotate(44 5.336 16.502)"
          />
          <path
            d="M5.956 10.189a2.318 2.318 0 1 0 0 4.636h4.697a2.318 2.318 0 0 0 0-4.636H5.956z"
            id="Rectangle-Copy"
            transform="rotate(44 8.305 12.507)"
          />
          <path
            d="M9.338 6.713a2.319 2.319 0 1 0 0 4.637h4.697a2.319 2.319 0 0 0 0-4.637H9.338z"
            id="Rectangle-Copy-2"
            transform="rotate(44 11.686 9.032)"
          />
          <path
            d="M13.002 3.144a2.318 2.318 0 1 0 0 4.636h4.697a2.318 2.318 0 0 0 0-4.636h-4.697z"
            id="Rectangle-Copy-3"
            transform="rotate(44 15.35 5.462)"
          />
        </g>
      </g>
    </g>
  </>
)
