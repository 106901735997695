import { useLocation } from 'react-router-dom'

import {
  ACTIVATE_ACCOUNT_PAGE,
  ADDRESS_PAGE,
  CHANGE_PASSWORD_PAGE,
  CREATE_ACCOUNT_PAGE,
  DASHBOARD_PAGE,
  ERROR_PAGE,
  FORGOT_PASSWORD_PAGE,
  LANDING_PAGE,
  LANGUAGE_PAGE,
  METERS_PAGE,
  ORDER_DETAIL_PAGE,
  ORDERS_PAGE,
  PROFILE_DETAIL_PAGE,
  PROFILE_PAGE,
  RESET_PASSWORD_PAGE,
  SIGN_IN_PAGE,
  SIGN_UP_PAGE,
  SUBSCRIPTION_PAGE
} from '../constants'

export default function useCurrentScreen() {
  const { pathname } = useLocation()

  return {
    isDashboardPage: () => pathname === DASHBOARD_PAGE,
    isLandingPage: () => pathname === LANDING_PAGE,
    isSignUpPage: () => pathname === SIGN_UP_PAGE,
    isSignInPage: () => pathname === SIGN_IN_PAGE,
    isSetPasswordPage: () => pathname === CREATE_ACCOUNT_PAGE,
    isForgotPasswordPage: () => pathname === FORGOT_PASSWORD_PAGE,
    isProfilePage: () => pathname === PROFILE_PAGE,
    isOrdersPage: () => pathname === ORDERS_PAGE,
    isOrderDetailPage: () => pathname === ORDER_DETAIL_PAGE,
    isProfileDetailPage: () => pathname === PROFILE_DETAIL_PAGE,
    isAddressPage: () => pathname === ADDRESS_PAGE,
    isSubscriptionPage: () => pathname === SUBSCRIPTION_PAGE,
    isLanguagePage: () => pathname === LANGUAGE_PAGE,
    isResetPasswordPage: () => pathname === RESET_PASSWORD_PAGE,
    isChangePasswordPage: () => pathname === CHANGE_PASSWORD_PAGE,
    isMetersPage: () => pathname === METERS_PAGE,
    isErrorPage: () => pathname === ERROR_PAGE,
    isActivateAccountPage: () => pathname === ACTIVATE_ACCOUNT_PAGE
  }
}
