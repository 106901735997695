import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const CopyIcon = createSvgIcon(
  <>
    <title>copy</title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="square"
      strokeLinejoin="miter"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <rect
        fill="none"
        height="26"
        stroke="#222222"
        strokeMiterlimit="10"
        width="22"
        x="3"
        y="5"
      />{' '}
      <line
        fill="none"
        stroke="#222222"
        strokeMiterlimit="10"
        x1="8"
        x2="20"
        y1="12"
        y2="12"
      />{' '}
      <line
        fill="none"
        stroke="#222222"
        strokeMiterlimit="10"
        x1="8"
        x2="20"
        y1="18"
        y2="18"
      />{' '}
      <line
        fill="none"
        stroke="#222222"
        strokeMiterlimit="10"
        x1="8"
        x2="12"
        y1="24"
        y2="24"
      />{' '}
      <polyline
        data-color="color-2"
        fill="none"
        points=" 6,1 29,1 29,28 "
        strokeMiterlimit="10"
      />
    </g>
  </>
)
