import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const AttachIcon = createSvgIcon(
  <>
    <title></title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="square"
      strokeLinejoin="miter"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <path
        d="M28.967,16.524,18.053,27.437a8.85,8.85,0,0,1-12.457,0h0a8.85,8.85,0,0,1,0-12.457L16.841,3.736a6.19,6.19,0,0,1,8.6,0h0a6.191,6.191,0,0,1,0,8.6L14.967,22.807a3.2,3.2,0,0,1-4.63,0h0a3.2,3.2,0,0,1,0-4.63L20.368,7.925"
        fill="none"
        stroke="#222222"
        strokeMiterlimit="10"
      />
    </g>
  </>
)
