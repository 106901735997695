import { History, Location } from 'history'

import { AppActionTypes } from './types'

export const getSelectedStyles = ({
  id,
  history
}: {
  id: string
  history: History
}) => ({
  type: AppActionTypes.GET_SELECTED_STYLES_REQUEST,
  payload: { id, history }
})

export const authenticate = ({
  email,
  orgId,
  history
}: {
  email: string
  orgId: string
  history: History
}) => ({
  type: AppActionTypes.AUTHENTICATION_REQUEST,
  payload: {
    email,
    orgId,
    history
  }
})

export const checkEmailExist = ({
  email,
  orgId
}: {
  email: string
  orgId: string
}) => ({
  type: AppActionTypes.CHECK_EMAIL_EXIST_REQUEST,
  payload: {
    email,
    orgId
  }
})

export const updateAppState = (data: Record<string, unknown>) => ({
  type: AppActionTypes.UPDATE_APP_STATE,
  payload: data
})

export const updateAuthStatus = (data: Record<string, unknown>) => ({
  type: AppActionTypes.UPDATE_AUTHENTICATION_STATUS,
  payload: data
})

export const checkUserLoggedIn = ({
  orgIdFromUrl,
  history,
  location
}: {
  orgIdFromUrl: string
  history: History
  location: Location
}) => ({
  type: AppActionTypes.CHECK_USER_LOGGED_IN_REQUEST,
  payload: { orgIdFromUrl, history, location }
})

export const logOut = ({
  history,
  location,
  isGlobalSignOut = false
}: {
  history: History
  location?: Location
  isGlobalSignOut?: boolean
}) => ({
  type: AppActionTypes.LOG_OUT,
  payload: { history, location, isGlobalSignOut }
})

export const checkPremiumFeature = ({
  organizationId,
  history
}: {
  organizationId: string
  history: History
}) => ({
  type: AppActionTypes.CHECK_PREMIUM_FEATURE_REQUEST,
  payload: {
    organizationId,
    featureId: 6,
    history
  }
})

export const getPrivacyAndSupportEmail = (brandId: string) => ({
  type: AppActionTypes.GET_TERMS_PRIVACY_AND_SUPPORT_EMAIL_REQUEST,
  payload: { brandId: brandId }
})
