import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const BellIcon = createSvgIcon(
  <>
    <title></title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="square"
      strokeLinejoin="miter"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <path
        d="M13,29.722a4.16,4.16,0,0,0,6,0"
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
      />{' '}
      <path
        d="M26,15V10.9A10.211,10.211,0,0,0,16,1,10.3,10.3,0,0,0,6,11v4c0,4.5-3.6,5.3-3.6,7.8,0,2.3,5.3,4.1,13.6,4.1s13.6-1.8,13.6-4.1C29.6,20.3,26,19.5,26,15Z"
        fill="none"
        stroke="#222222"
        strokeMiterlimit="10"
      />
    </g>
  </>
)
