import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const CycleSmallIcon = createSvgIcon(
  <>
    <title></title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="square"
      strokeLinejoin="miter"
      strokeWidth="4"
      transform="translate(0.5 0.5)"
    >
      <path
        d="M11,4l4,4H9 c-4.418,0-8,3.582-8,8v0c0,4.418,3.582,8,8,8h2"
        fill="none"
        stroke="#222222"
        strokeMiterlimit="10"
      />{' '}
      <path
        d="M21,28l-4-4 h6c4.418,0,8-3.582,8-8v0c0-4.418-3.582-8-8-8h-2"
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
      />
    </g>
  </>
)
