import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const ConnectIcon = createSvgIcon(
  <>
    <title>connect</title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="square"
      strokeLinejoin="miter"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <line
        data-cap="butt"
        data-color="color-2"
        fill="none"
        strokeLinecap="butt"
        strokeMiterlimit="10"
        x1="17"
        x2="11"
        y1="24"
        y2="24"
      />{' '}
      <line
        data-cap="butt"
        data-color="color-2"
        fill="none"
        strokeLinecap="butt"
        strokeMiterlimit="10"
        x1="23.351"
        x2="20.577"
        y1="19.762"
        y2="15.323"
      />{' '}
      <line
        data-cap="butt"
        data-color="color-2"
        fill="none"
        strokeLinecap="butt"
        strokeMiterlimit="10"
        x1="13.351"
        x2="10.761"
        y1="12.238"
        y2="16.382"
      />{' '}
      <circle
        cx="6"
        cy="24"
        fill="none"
        r="5"
        stroke="#222222"
        strokeMiterlimit="10"
      />{' '}
      <circle
        cx="26"
        cy="24"
        fill="none"
        r="5"
        stroke="#222222"
        strokeMiterlimit="10"
      />{' '}
      <circle
        cx="16"
        cy="8"
        fill="none"
        r="5"
        stroke="#222222"
        strokeMiterlimit="10"
      />
    </g>
  </>
)
