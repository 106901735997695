import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const DashboardIcon = createSvgIcon(
  <>
    <title>dashboard</title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="square"
      strokeLinejoin="miter"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <line
        data-cap="butt"
        data-color="color-2"
        fill="none"
        strokeLinecap="butt"
        strokeMiterlimit="10"
        x1="1.3"
        x2="30.7"
        y1="19"
        y2="19"
      />{' '}
      <line
        data-cap="butt"
        data-color="color-2"
        fill="none"
        strokeLinecap="butt"
        strokeMiterlimit="10"
        x1="9"
        x2="16"
        y1="8"
        y2="19"
      />{' '}
      <circle
        cx="16"
        cy="16"
        fill="none"
        r="15"
        stroke="#222222"
        strokeMiterlimit="10"
      />
    </g>
  </>
)
