import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const CircleAddIcon = createSvgIcon(
  <>
    <title>circle add</title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="square"
      strokeLinejoin="miter"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <line
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
        x1="16"
        x2="16"
        y1="9"
        y2="23"
      />{' '}
      <line
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
        x1="23"
        x2="9"
        y1="16"
        y2="16"
      />{' '}
      <circle
        cx="16"
        cy="16"
        fill="none"
        r="15"
        stroke="#222222"
        strokeMiterlimit="10"
      />
    </g>
  </>
)
