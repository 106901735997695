import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const ChatIcon = createSvgIcon(
  <>
    <title>chat</title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="square"
      strokeLinejoin="miter"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <path
        d="M27.5,21.9 C29.7,19.8,31,17,31,14c0-6.8-6.7-12-15-12S1,7.2,1,14c0,6.8,6.7,12.3,15,12.3c1.5,0,2.9-0.2,4.2-0.5L28,29L27.5,21.9z"
        fill="none"
        stroke="#222222"
        strokeMiterlimit="10"
      />{' '}
      <line
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
        x1="9"
        x2="23"
        y1="11"
        y2="11"
      />{' '}
      <line
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
        x1="9"
        x2="17"
        y1="17"
        y2="17"
      />
    </g>
  </>
)
