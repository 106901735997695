import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const CustomTaskIcon = createSvgIcon(
  <>
    <title>custom task</title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <rect
        fill="none"
        height="8"
        stroke="#222222"
        strokeMiterlimit="10"
        width="28"
        x="2"
        y="4"
      />{' '}
      <line
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
        x1="2"
        x2="30"
        y1="20"
        y2="20"
      />{' '}
      <line
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
        x1="2"
        x2="30"
        y1="28"
        y2="28"
      />
    </g>
  </>
)
