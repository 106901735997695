import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const GridViewIcon = createSvgIcon(
  <>
    <title></title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <rect
        fill="none"
        height="6"
        stroke="#222222"
        strokeMiterlimit="10"
        width="6"
        x="2"
        y="2"
      />{' '}
      <rect
        fill="none"
        height="6"
        stroke="#222222"
        strokeMiterlimit="10"
        width="6"
        x="13"
        y="2"
      />{' '}
      <rect
        data-color="color-2"
        fill="none"
        height="6"
        strokeMiterlimit="10"
        width="6"
        x="24"
        y="2"
      />{' '}
      <rect
        fill="none"
        height="6"
        stroke="#222222"
        strokeMiterlimit="10"
        width="6"
        x="2"
        y="13"
      />{' '}
      <rect
        data-color="color-2"
        fill="none"
        height="6"
        strokeMiterlimit="10"
        width="6"
        x="13"
        y="13"
      />{' '}
      <rect
        fill="none"
        height="6"
        stroke="#222222"
        strokeMiterlimit="10"
        width="6"
        x="24"
        y="13"
      />{' '}
      <rect
        data-color="color-2"
        fill="none"
        height="6"
        strokeMiterlimit="10"
        width="6"
        x="2"
        y="24"
      />{' '}
      <rect
        fill="none"
        height="6"
        stroke="#222222"
        strokeMiterlimit="10"
        width="6"
        x="13"
        y="24"
      />{' '}
      <rect
        fill="none"
        height="6"
        stroke="#222222"
        strokeMiterlimit="10"
        width="6"
        x="24"
        y="24"
      />
    </g>
  </>
)
