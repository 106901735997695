import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const CircleEuroIcon = createSvgIcon(
  <>
    <title>circle euro</title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="square"
      strokeLinejoin="miter"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <circle
        cx="16"
        cy="16"
        fill="none"
        r="15"
        stroke="#222222"
        strokeMiterlimit="10"
      />{' '}
      <path
        d="M20.4,23.2 c-1.05,0.55-2.124,0.8-3.2,0.8c-4.418,0-7.2-3.582-7.2-8s2.782-8,7.2-8c1.075,0,2.225,0.2,3.2,0.8"
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
      />{' '}
      <line
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
        x1="7"
        x2="16"
        y1="14"
        y2="14"
      />{' '}
      <line
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
        x1="7"
        x2="16"
        y1="18"
        y2="18"
      />
    </g>
  </>
)
