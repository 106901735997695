import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const FilterAllIcon = createSvgIcon(
  <>
    <title></title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <polygon
        fill="none"
        points="30 5 19 16 19 26 13 30 13 16 2 5 2 1 30 1 30 5"
        stroke="#222222"
        strokeMiterlimit="10"
      />
    </g>
  </>
)
