import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const FlowchartIcon = createSvgIcon(
  <>
    <title>flowchart</title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="square"
      strokeLinejoin="miter"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <polygon
        data-color="color-2"
        fill="none"
        points="16 16 23 12 30 16 23 20 16 16"
        strokeMiterlimit="10"
      />
      <rect
        fill="none"
        height="6"
        stroke="#222222"
        strokeMiterlimit="10"
        width="12"
        x="2"
        y="2"
      />
      <rect
        fill="none"
        height="6"
        stroke="#222222"
        strokeMiterlimit="10"
        width="12"
        x="2"
        y="24"
      />
      <line
        fill="none"
        stroke="#222222"
        strokeMiterlimit="10"
        x1="8"
        x2="8"
        y1="11"
        y2="21"
      />
      <polyline
        fill="none"
        points="17 5 23 5 23 9"
        stroke="#222222"
        strokeMiterlimit="10"
      />
      <polyline
        fill="none"
        points="17 27 23 27 23 23"
        stroke="#222222"
        strokeMiterlimit="10"
      />
    </g>
  </>
)
