import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const BagIcon = createSvgIcon(
  <>
    <title>bag</title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="square"
      strokeLinejoin="miter"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <line
        data-cap="butt"
        fill="none"
        stroke="#222222"
        strokeLinecap="butt"
        strokeMiterlimit="10"
        x1="3"
        x2="29"
        y1="6"
        y2="6"
      />{' '}
      <polygon
        fill="none"
        points="29,31 3,31 3,6 8,1 24,1 29,6 "
        stroke="#222222"
        strokeMiterlimit="10"
      />{' '}
      <path
        d="M23,12 c0,3.9-3.1,7-7,7s-7-3.1-7-7"
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
      />
    </g>
  </>
)
