import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const BookmarkIcon = createSvgIcon(
  <>
    <title>bookmark</title>
    <g className="nc-icon-wrapper" fill="#222222">
      <g
        fill="none"
        fillRule="evenodd"
        id="Symbols"
        stroke="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      >
        <g id="bookmark" stroke="#222222">
          <path
            d="M28 30.5l-12-7-12 7v-26a3 3 0 0 1 3-3h18a3 3 0 0 1 3 3v26z"
            id="Path"
          />
        </g>
      </g>
    </g>
  </>
)
