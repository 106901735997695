import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const CircleDollarIcon = createSvgIcon(
  <>
    <title>circle dollar</title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="square"
      strokeLinejoin="miter"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <circle
        cx="16"
        cy="16"
        fill="none"
        r="15"
        stroke="#222222"
        strokeMiterlimit="10"
      />{' '}
      <line
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
        x1="16"
        x2="16"
        y1="6"
        y2="26"
      />{' '}
      <path
        d="M21,10 c-2.273-1.181-9.574-2.072-9.574,1.992c0,4.865,9.091,3.148,9.091,7.441s-5.909,3.971-10,2.576"
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
      />
    </g>
  </>
)
