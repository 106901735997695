import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const CustomerIcon = createSvgIcon(
  <>
    <title></title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="square"
      strokeLinejoin="miter"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <path
        d="M19,20h-6 c-4.971,0-9,4.029-9,9v0c0,0,4.5,2,12,2s12-2,12-2v0C28,24.029,23.971,20,19,20z"
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
      />{' '}
      <path
        d="M9,8c0-3.866,3.134-7,7-7 s7,3.134,7,7s-3.134,8-7,8S9,11.866,9,8z"
        fill="none"
        stroke="#222222"
        strokeMiterlimit="10"
      />
    </g>
  </>
)
