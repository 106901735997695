export const IVY_SERVER_API_URL =
  process.env.NODE_ENV === 'development'
    ? 'https://dev.epilot.cloud/'
    : process.env.REACT_APP_IVY_SERVER_API_URL

export const PORTAL_API_URL =
  process.env.NODE_ENV === 'development'
    ? 'https://4njbo15nu6.execute-api.eu-central-1.amazonaws.com/dev/v1/ec-portal'
    : process.env.REACT_APP_PORTAL_API_URL

export const STYLING_EDITOR_API_URL =
  process.env.NODE_ENV === 'development'
    ? 'https://mil4px1sek.execute-api.eu-central-1.amazonaws.com/dev/v1'
    : process.env.REACT_APP_STYLING_EDITOR_API_URL

export const PORTAL_METER_READING_API_URL =
  process.env.NODE_ENV === 'development'
    ? 'https://lhu57ni95f.execute-api.eu-central-1.amazonaws.com/review-dev-4jxwt5/v1/ec-portal'
    : process.env.REACT_APP_PORTAL_METER_READING_API_URL

export const PORTAL_COGNITO_TRIGGER_API_URL =
  process.env.NODE_ENV === 'development'
    ? 'https://c1s9t8gaz2.execute-api.eu-central-1.amazonaws.com/dev/v1/ecp-cognito-trigger'
    : process.env.REACT_APP_PORTAL_COGNITO_TRIGGER_API_URL

export const IvyServerAPIName = 'IvyServerAPIName'
export const PortalAPIName = 'PortalAPIName'
export const StylingEditorAPIName = 'StylingEditorAPIName'
export const PortalMeterReadingAPIName = 'PortalMeterReadingAPIName'
export const PortalCognitoTriggerAPIName = 'PortalCognitoTriggerAPIName'
