import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const CommissioningGasIcon = createSvgIcon(
  <>
    <title></title>
    <g className="nc-icon-wrapper" fill="none">
      <rect
        height="30"
        rx="2"
        stroke="#222222"
        transform="rotate(-.342 2 1)"
        width="28"
        x="2"
        y="1"
      />
      <path
        d="M7.744 20.675l.849.84 1.77-1.793"
        stroke="#222222"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.774 25.675l.849.84 1.77-1.793"
        stroke="#222222"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M13.509 20.595l11-.066" stroke="#222222" />
      <path d="M13.539 25.595l11-.066" stroke="#222222" />
      <path
        clipRule="evenodd"
        d="M14.445 9.186c0-.324-.002-.554-.006-.688a3.88 3.88 0 0 0-.09-.703c-.134-.605-.664-1.808-.852-1.807-.188.001.204 1.308.039 1.812-.089.277-.214.54-.372.784a9.45 9.45 0 0 1-.56.724c-.838.897-1.284 1.743-1.336 2.54-.079 1.197.087 1.716.884 2.74.42.54 1.239 1.046 1.977 1.322.936.35 1.964.296 2.219.278.869-.061 1.222-.253 1.659-.417.386-.145.944-.52 1.349-.89.237-.217.554-.614.948-1.191.424-.779.61-1.572.558-2.38-.079-1.212-.623-2.677-.866-2.767-.243-.09.152 1.582-.227 2.365-.221.456-.577.8-1.067 1.03a.087.087 0 0 1-.116-.117c.584-1.23.656-2.389.213-3.476-.375-.922-.632-1.321-.764-1.517-.084-.124-.25-.378-.521-.687-.158-.18-.305-.367-.55-.605-1.548-1.508-3.452-1.693-3.48-1.515-.026.179 1.513.818 1.781 2.45.166 1.005-.055 1.927-.661 2.767a.087.087 0 0 1-.159-.052z"
        fillRule="evenodd"
        stroke="#222222"
        strokeLinecap="round"
        strokeLinejoin="bevel"
      />
    </g>
  </>
)
