import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const GearIcon = createSvgIcon(
  <>
    <title>gear</title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="square"
      strokeLinejoin="miter"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <circle
        cx="16"
        cy="16"
        data-color="color-2"
        fill="none"
        r="4"
        strokeMiterlimit="10"
      />{' '}
      <path
        d="M29.015,13.752l-2.321-.29a10.915,10.915,0,0,0-1.339-3.227l1.438-1.849a2.265,2.265,0,0,0-3.179-3.179L21.765,6.645a10.915,10.915,0,0,0-3.227-1.339l-.29-2.321a2.265,2.265,0,0,0-4.5,0l-.29,2.321a10.915,10.915,0,0,0-3.227,1.339L8.386,5.207A2.265,2.265,0,0,0,5.207,8.386l1.438,1.849a10.915,10.915,0,0,0-1.339,3.227l-2.321.29a2.265,2.265,0,0,0,0,4.5l2.321.29a10.915,10.915,0,0,0,1.339,3.227L5.207,23.614a2.265,2.265,0,0,0,3.179,3.179l1.849-1.438a10.915,10.915,0,0,0,3.227,1.339l.29,2.321a2.265,2.265,0,0,0,4.5,0l.29-2.321a10.915,10.915,0,0,0,3.227-1.339l1.849,1.438a2.265,2.265,0,0,0,3.179-3.179l-1.438-1.849a10.915,10.915,0,0,0,1.339-3.227l2.321-.29a2.265,2.265,0,0,0,0-4.5Z"
        fill="none"
        stroke="#222222"
        strokeMiterlimit="10"
      />
    </g>
  </>
)
