import { format } from 'date-fns'
import { enGB } from 'date-fns/locale'

export function convertDateTime(
  dateTimeString?: string,
  locale?: Locale,
  formatDate?: string
) {
  if (!dateTimeString) {
    return format(new Date(), formatDate ?? 'd MMM', {
      locale: locale ?? enGB
    })
  }

  return format(new Date(dateTimeString), formatDate ?? 'd MMM', {
    locale: locale ?? enGB
  })
}
