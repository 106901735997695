import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const CommissioningWaterIcon = createSvgIcon(
  <>
    <title></title>
    <g className="nc-icon-wrapper" fill="none">
      <rect
        height="30"
        rx="2"
        stroke="#222222"
        transform="rotate(-.342 2 1)"
        width="28"
        x="2"
        y="1"
      />
      <path
        d="M7.744 20.675l.849.84 1.77-1.793"
        stroke="#222222"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.774 25.675l.849.84 1.77-1.793"
        stroke="#222222"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M13.509 20.595l11-.066" stroke="#222222" />
      <path d="M13.539 25.595l11-.066" stroke="#222222" />
      <path
        clipRule="evenodd"
        d="M15.963 3.976c-.136 0-.164.813-.292 1.195a9.16 9.16 0 0 1-.432 1.018c-.212.42-.397.766-.557 1.036-.16.269-.419.662-.777 1.177-.325.504-.564.883-.72 1.139-.233.385-.44.764-.548.988-.11.224-.692 1.085-.684 2.273.007 1.188.402 1.842.783 2.383.382.541 1.217 1.256 1.682 1.48.465.225.913.315 1.622.31.71-.004 1.158-.112 1.59-.33.434-.216 1.136-.753 1.683-1.5.547-.746.84-1.555.75-2.518a5 5 0 0 0-.69-2.138c-.308-.538-.454-.799-.705-1.175l-.621-.936a20.27 20.27 0 0 1-.736-1.096c-.149-.247-.36-.615-.635-1.101a8.437 8.437 0 0 1-.42-.99c-.121-.376-.158-1.216-.293-1.215z"
        fillRule="evenodd"
        stroke="#222222"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.466 13.383s-.208.519-.493.944a2.535 2.535 0 0 1-.776.732"
        stroke="#222222"
        strokeLinecap="round"
      />
    </g>
  </>
)
