import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const CrownIcon = createSvgIcon(
  <>
    <title>crown</title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="square"
      strokeLinejoin="miter"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <line
        data-cap="butt"
        data-color="color-2"
        fill="none"
        strokeLinecap="butt"
        strokeMiterlimit="10"
        x1="1"
        x2="31"
        y1="24"
        y2="24"
      />{' '}
      <path
        d="M31,8l-8,6L16,4,9,14,1,8V26a3,3,0,0,0,3,3H28a3,3,0,0,0,3-3Z"
        fill="none"
        stroke="#222222"
        strokeMiterlimit="10"
      />
    </g>
  </>
)
