import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const LanguageIcon = createSvgIcon(
  <>
    <title>language</title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="square"
      strokeLinejoin="miter"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <path
        d="M1,29h2 c1.1,0,2-0.9,2-2v-2l-2.4-1.2c-1.5-0.7-1.5-2.8,0-3.6L5,19v-2l3.6-1.4c0.7-0.3,0.8-1.1,0.3-1.6L5,10V3"
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
      />{' '}
      <path
        d="M10,23l4-5V9 c0-1.7,1.3-3,3-3h11c1.7,0,3,1.3,3,3v11c0,1.7-1.3,3-3,3H10z"
        fill="none"
        stroke="#222222"
        strokeMiterlimit="10"
      />{' '}
      <line
        fill="none"
        stroke="#222222"
        strokeMiterlimit="10"
        x1="19"
        x2="26"
        y1="12"
        y2="12"
      />{' '}
      <line
        fill="none"
        stroke="#222222"
        strokeMiterlimit="10"
        x1="19"
        x2="26"
        y1="17"
        y2="17"
      />
    </g>
  </>
)
