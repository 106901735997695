import { History } from 'history'

import { ProfileActionTypes } from './types'

export const getProfile = (id: number) => ({
  type: ProfileActionTypes.GET_PROFILE_REQUEST,
  payload: id
})

export const updateProfile = (data: Record<string, unknown>) => ({
  type: ProfileActionTypes.UPDATE_PROFILE_INFO,
  payload: data
})

export const updateProfileState = (data: Record<string, unknown>) => ({
  type: ProfileActionTypes.UPDATE_PROFILE_STATE,
  payload: data
})

export const updateLanguage = (
  customerId: number,
  data: Record<string, unknown>
) => ({
  type: ProfileActionTypes.UPDATE_LANGUAGE_REQUEST,
  payload: { customerId: customerId, data: data }
})

export const updateSubscription = (customerId: number, data: boolean) => ({
  type: ProfileActionTypes.UPDATE_SUB_REQUEST,
  payload: { customerId, data }
})

export const updateAvatar = (
  customerId: number,
  name: string | null,
  base64?: string
) => ({
  type: ProfileActionTypes.UPLOAD_AVATAR_REQUEST,
  payload: { customerId: customerId, name: name, base64: base64 }
})

export const deleteAvatar = (customerId: number) => ({
  type: ProfileActionTypes.DELETE_AVATAR_REQUEST,
  payload: { customerId: customerId, name: null }
})

export const deleteUserAccount = ({ history }: { history: History }) => ({
  type: ProfileActionTypes.DELETE_USER_ACCOUNT_REQUEST,
  payload: { history }
})

export const changePassword = ({
  currentPassword,
  newPassword,
  history
}: {
  currentPassword: string
  newPassword: string
  history: History
}) => ({
  type: ProfileActionTypes.CHANGE_PASSWORD_REQUEST,
  payload: {
    currentPassword,
    newPassword,
    history
  }
})
