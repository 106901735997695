import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const AddIcon = createSvgIcon(
  <>
    <title>add</title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <line
        fill="none"
        stroke="#222222"
        strokeMiterlimit="10"
        x1="16"
        x2="16"
        y1="2"
        y2="30"
      />{' '}
      <line
        fill="none"
        stroke="#222222"
        strokeMiterlimit="10"
        x1="30"
        x2="2"
        y1="16"
        y2="16"
      />
    </g>
  </>
)
