import { useHistory } from 'react-router'

import {
  ACTIVATION_PENDING_PAGE,
  ADDRESS_PAGE,
  CHANGE_PASSWORD_PAGE,
  CREATE_ACCOUNT_PAGE,
  DASHBOARD_PAGE,
  ERROR_PAGE,
  FORGOT_PASSWORD_PAGE,
  LANDING_PAGE,
  LANGUAGE_PAGE,
  METERS_PAGE,
  ORDER_DETAIL_PAGE,
  ORDERS_PAGE,
  PROFILE_DETAIL_PAGE,
  PROFILE_PAGE,
  SET_PASSWORD_PAGE,
  SIGN_IN_PAGE,
  SIGN_UP_PAGE,
  SUBSCRIPTION_PAGE
} from '../constants'

export default function useNavigation() {
  const history = useHistory()

  return {
    goToDashboardPage: () => history.push(DASHBOARD_PAGE),
    goToLandingPage: () => history.push(LANDING_PAGE),
    goToSignUpPage: () => history.push(SIGN_UP_PAGE),
    goToSignInPage: () => history.push(SIGN_IN_PAGE),
    goToCreateAccountPage: () => history.push(CREATE_ACCOUNT_PAGE),
    goToForgotPasswordPage: () => history.push(FORGOT_PASSWORD_PAGE),
    goToProfilePage: () => history.push(PROFILE_PAGE),
    goToOrdersPage: () => history.push(ORDERS_PAGE),
    goToOrderDetailPage: () => history.push(ORDER_DETAIL_PAGE),
    goToProfileDetailPage: () => history.push(PROFILE_DETAIL_PAGE),
    goToAddressPage: () => history.push(ADDRESS_PAGE),
    goToSubscriptionPage: () => history.push(SUBSCRIPTION_PAGE),
    goToLanguagePage: () => history.push(LANGUAGE_PAGE),
    goToSetPasswordPage: () => history.push(SET_PASSWORD_PAGE),
    goToChangePasswordPage: () => history.push(CHANGE_PASSWORD_PAGE),
    goToMetersPage: () => history.push(METERS_PAGE),
    goToErrorPage: () => history.push(ERROR_PAGE),
    goToActivateAccountPage: () => history.push(ACTIVATION_PENDING_PAGE),
    goBack: () => history.goBack()
  }
}
