import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const DataTableIcon = createSvgIcon(
  <>
    <title>data table</title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <rect
        fill="none"
        height="28"
        stroke="#222222"
        strokeMiterlimit="10"
        width="28"
        x="2"
        y="2"
      />
      <line
        fill="none"
        stroke="#222222"
        strokeMiterlimit="10"
        x1="30"
        x2="2"
        y1="10"
        y2="10"
      />
      <line
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
        x1="6"
        x2="14"
        y1="22"
        y2="22"
      />
      <line
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
        x1="18"
        x2="26"
        y1="22"
        y2="22"
      />
      <line
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
        x1="6"
        x2="14"
        y1="18"
        y2="18"
      />
      <line
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
        x1="18"
        x2="26"
        y1="18"
        y2="18"
      />
      <line
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
        x1="6"
        x2="14"
        y1="14"
        y2="14"
      />
      <line
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
        x1="18"
        x2="26"
        y1="14"
        y2="14"
      />
      <line
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
        x1="6"
        x2="14"
        y1="26"
        y2="26"
      />
      <line
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
        x1="18"
        x2="26"
        y1="26"
        y2="26"
      />
      <line
        fill="none"
        stroke="#222222"
        strokeMiterlimit="10"
        x1="6"
        x2="14"
        y1="6"
        y2="6"
      />
      <line
        fill="none"
        stroke="#222222"
        strokeMiterlimit="10"
        x1="18"
        x2="26"
        y1="6"
        y2="6"
      />
    </g>
  </>
)
