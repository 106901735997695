import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const ButtonClickIcon = createSvgIcon(
  <>
    <title>button click</title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="square"
      strokeLinejoin="miter"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <polyline
        data-cap="butt"
        fill="none"
        points="10,18 1,18 1,1 31,1 31,18 26,18 "
        stroke="#222222"
        strokeLinecap="butt"
        strokeMiterlimit="10"
      />{' '}
      <line
        data-cap="butt"
        data-color="color-2"
        fill="none"
        strokeLinecap="butt"
        strokeMiterlimit="10"
        x1="17"
        x2="26"
        y1="18"
        y2="27"
      />{' '}
      <polygon
        data-color="color-2"
        fill="none"
        points=" 7,8 13,26 17,18 25,14 "
        strokeMiterlimit="10"
      />
    </g>
  </>
)
