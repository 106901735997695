import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const ChartBarIcon = createSvgIcon(
  <>
    <title>chart bar</title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="square"
      strokeLinejoin="miter"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <rect
        fill="none"
        height="13"
        stroke="#222222"
        strokeMiterlimit="10"
        width="6"
        x="13"
        y="18"
      />{' '}
      <rect
        fill="none"
        height="7"
        stroke="#222222"
        strokeMiterlimit="10"
        width="6"
        x="1"
        y="24"
      />{' '}
      <rect
        fill="none"
        height="19"
        stroke="#222222"
        strokeMiterlimit="10"
        width="6"
        x="25"
        y="12"
      />{' '}
      <polyline
        data-cap="butt"
        data-color="color-2"
        fill="none"
        points="6,11 11,6 16,11 26,1 "
        strokeLinecap="butt"
        strokeMiterlimit="10"
      />{' '}
      <polyline
        data-color="color-2"
        fill="none"
        points=" 20,1 26,1 26,7 "
        strokeMiterlimit="10"
      />
    </g>
  </>
)
