import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const BookmarkFilledIcon = createSvgIcon(
  <>
    <title></title>
    <g className="nc-icon-wrapper" fill="#222222">
      <path
        d="M26,1H6C5.4,1,5,1.4,5,2v28c0,0.4,0.2,0.7,0.5,0.9c0.3,0.2,0.7,0.2,1,0l9.5-5.7l9.5,5.7 C25.6,31,25.8,31,26,31c0.2,0,0.3,0,0.5-0.1c0.3-0.2,0.5-0.5,0.5-0.9V2C27,1.4,26.6,1,26,1z"
        fill="#222222"
      />
    </g>
  </>
)
