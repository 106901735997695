import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const FileParagraphIcon = createSvgIcon(
  <>
    <title>file paragraph</title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <rect
        fill="none"
        height="30"
        stroke="#222222"
        strokeMiterlimit="10"
        width="26"
        x="3"
        y="1"
      />{' '}
      <line
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
        x1="19"
        x2="23"
        y1="8"
        y2="8"
      />{' '}
      <line
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
        x1="19"
        x2="23"
        y1="13"
        y2="13"
      />{' '}
      <line
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
        x1="9"
        x2="23"
        y1="18"
        y2="18"
      />{' '}
      <line
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
        x1="9"
        x2="23"
        y1="23"
        y2="23"
      />{' '}
      <rect
        data-color="color-2"
        fill="none"
        height="5"
        strokeMiterlimit="10"
        width="6"
        x="9"
        y="8"
      />
    </g>
  </>
)
