import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const BulletTwoIcon = createSvgIcon(
  <>
    <title>bullet two</title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="square"
      strokeLinejoin="miter"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <circle
        cx="5"
        cy="5"
        fill="none"
        r="4"
        stroke="#222222"
        strokeMiterlimit="10"
      />{' '}
      <line
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
        x1="15"
        x2="31"
        y1="5"
        y2="5"
      />{' '}
      <line
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
        x1="15"
        x2="31"
        y1="13"
        y2="13"
      />{' '}
      <circle
        cx="5"
        cy="21"
        fill="none"
        r="4"
        stroke="#222222"
        strokeMiterlimit="10"
      />{' '}
      <line
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
        x1="15"
        x2="31"
        y1="21"
        y2="21"
      />{' '}
      <line
        data-color="color-2"
        fill="none"
        strokeMiterlimit="10"
        x1="15"
        x2="31"
        y1="29"
        y2="29"
      />
    </g>
  </>
)
