import React from 'react'

import { createSvgIcon } from './utils/create-svg-icon'

export const BriefcaseIcon = createSvgIcon(
  <>
    <title>briefcase</title>
    <g
      className="nc-icon-wrapper"
      fill="#222222"
      stroke="#222222"
      strokeLinecap="square"
      strokeLinejoin="miter"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <polyline
        data-color="color-2"
        fill="none"
        points=" 11,7 11,2 21,2 21,7 "
        strokeMiterlimit="10"
      />{' '}
      <polyline
        fill="none"
        points="12,23 1,23 1,7 31,7 31,23 20,23 "
        stroke="#222222"
        strokeMiterlimit="10"
      />{' '}
      <polyline
        fill="none"
        points="30,26 30,30 2,30 2,26 "
        stroke="#222222"
        strokeMiterlimit="10"
      />{' '}
      <rect
        data-color="color-2"
        fill="none"
        height="6"
        strokeMiterlimit="10"
        width="8"
        x="12"
        y="20"
      />
    </g>
  </>
)
