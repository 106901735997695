import MuiButton, {
  ButtonProps as MuiButtonProps
} from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

import { LoadingIcon } from '../loading-icon'

export interface ButtonProps extends MuiButtonProps {
  className?: string
  children?: React.ReactElement | string
  variant?: 'text' | 'outlined' | 'contained'
  color?: 'inherit' | 'primary' | 'secondary' | 'default'
  floating?: boolean
  isLoading?: boolean
}

/**
 *  There are multiple types of buttons, that can be set by the
 * `variant`=(contained | outlined | text) and `color`=(primary |
 * secondary) props
 *
 * There are different sized buttons, using the `size`=(small | | large)
 * prop you can set it
 *
 * Buttons can have icons inside, you can add them by passing
 * `startIcon`=(icon) or `endIcon`=(icon) to the button component as prop
 */
export const Button = (props: ButtonProps): React.ReactElement => {
  const {
    children,
    className,
    variant,
    color,
    floating,
    isLoading = false,
    ...restProps
  } = props
  const useStyles = makeStyles({
    button: {
      padding: '0',
      minWidth: '0'
    }
  })
  const classes = useStyles()

  return (
    <MuiButton
      {...restProps}
      className={`epc-btn ${className ? className : ''} ${
        floating ? classes.button : ''
      }`}
      color={color}
      variant={variant}
    >
      <div>
        {isLoading ? <LoadingIcon height={'16px'} width={'16px'} /> : children}
      </div>
    </MuiButton>
  )
}
